<template>
	<article class="plan-inline-item" :class="plan_card_class">
		<a class="plan-inline-item__link" :href="prescription.url">
			<span class="visually-hidden">{{ $t('buttons.open_prescription') }}</span>
		</a>

		<div class="plan-inline-item__progress"
			:style="`background-image: conic-gradient(var(--high) ${calcDegrees}deg, transparent ${calcDegrees}deg)`"
		>
			<span>{{ prescription.result }}%</span>
		</div>

		<h4 class="plan-inline-item__title">
			{{ prescription.item_title }}
		</h4>
		<p v-if="!recommended" class="plan-inline-item__descr">
			{{ parsedDate(prescription.date_from) }}&nbsp;&mdash; {{ parsedDate(prescription.date_to) }}.
			<span class="plan-inline-item__info">{{ currentDays }} {{ daysLeft }}</span>
		</p>
	</article>
</template>

<script>
export default {
	name: "PlanInlineItem",

	props: {
		prescription: Object,
		currentCompany: {
			type: String,
			default: ''
		},
		recommended: {
			type: Boolean,
			default: false
		},
		indexSelection: {
			type: Number,
		},
		indexItem: {
			type: Number,
		}
	},

	data() {
		return {

		};
	},

	computed: {
		daysLeft() {
			if (this.prescription.how_many_days_left == 1 && this.prescription.result != 100) {
				return this.$t('deadlines.last_day');
			} else if (this.prescription.how_many_days_left == 0 && this.prescription.result != 100) {
				return this.$t('deadlines.late');
			} else if (this.prescription.result == 100) {
				return this.$t('deadlines.finished');
			} else {
				let days = this.prescription.how_many_days_left;
				return this.$t('days', days);
			}
		},

		currentDays() {
			if (this.prescription.how_many_days_left == 0 || this.prescription.how_many_days_left == 1) {
				return this.$t('assignment');
			} else if (this.prescription.result == 100) {
				return this.$t('assignment');
			} else {
				return this.$t('deadlines.current');
			}
		},

		plan_card_class() {
			if (!this.recommended) {
				if (this.prescription.result == 100) {
					return "plan-inline-item--high";
				}

				if (this.prescription.how_many_days_left == 1 && this.prescription.result != 100) {
					return "plan-inline-item--upper-middle";
				}

				if (this.prescription.how_many_days_left == 0 && this.prescription.result != 100) {
					return "plan-inline-item--low";
				}
			}
		},

		calcDegrees() {
			return this.prescription.result * 3.6;
		}
	},

	methods: {
		parsedDate(date) {
			const arr = date.split(/[\-\+ :T]/);
			const resultDate = new Date();

			resultDate.setUTCFullYear(arr[0]);
			resultDate.setUTCMonth(arr[1] - 1);
			resultDate.setUTCDate(arr[2]);

			return moment(resultDate).format('D MMMM YYYY');
		},
	}
};
</script>
