<template>
	<section class="simular-courses">
		<div class="simular-courses__inner">
			<div class="simular-courses__card">
				<h2 class="simular-courses__title">{{ $t('similar_courses') }}</h2>
				<ul class="simular-courses__list">
					<li v-for="course in courses" :key="'recommended_course_' + course.id" class="simular-courses__item">
						<CoursePreview
							:course="course"
							:full_only="false"
						/>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import CoursePreview from "./Catalog/CoursePreview.vue";

export default {
	props: {
		courses: {
			type: Array,
			required: true
		}
	},

	components: {
		CoursePreview
	}
}
</script>
