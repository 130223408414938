import { createApp } from 'vue';
import { createStore } from 'vuex';
import mitt from 'mitt';
import { VueDraggableNext } from 'vue-draggable-next';
import i18n from '../assets/locales/i18n.js';

import Guide from '../components/desktop/Guides/Guide.vue';
import Sandbox from '../components/desktop/Courses/Sandbox.vue';
import StartAssignmentWidget from '../components/desktop/Start/AssignmentWidget.vue';
import StartBanner from '../components/desktop/Start/Banner.vue';
import TestingEngResult from '../components/desktop/Testings/TestingEngResult.vue';
import TimeCode from '../components/desktop/Courses/TimeCode.vue';
import RbcStart from '../components/desktop/Courses/RbcStart.vue'
import PlanTopic from '../components/desktop/Courses/PlanTopic.vue';
import PlanSelection from '../components/desktop/Courses/PlanSelection.vue';
import WishSelection from "../components/desktop/Courses/WishSelection.vue";
import UserCompletedCourses from '../components/desktop/Cabinet/UserCompletedCourses.vue';
import CatalogSelection from '../components/desktop/Courses/Catalog/Selection.vue';
import CoursePreviewPopup from '../components/desktop/Courses/Catalog/CoursePreviewPopup.vue';
import CoursesCatalog from '../components/desktop/Courses/Catalog.vue';
import Topic from '../components/desktop/Courses/Topic.vue';
import CoursesPresets from '../components/desktop/Courses/Presets.vue';
import CoursesCatalogHeader from '../components/desktop/Courses/Header.vue';
import ProgramsList from '../components/desktop/Programs/ProgramsList.vue';
import PrivacyPopup from '../components/desktop/shared/PrivacyPopup.vue';
import TermAndPrivacyForm from '../components/desktop/shared/TermAndPrivacyForm.vue';
import ConfirmPopup from '../components/desktop/shared/ConfirmPopup.vue';
import LiveEventForm from '../components/desktop/LiveEvent/Form.vue';
import LiveEventList from '../components/desktop/LiveEvent/List.vue';
import ProgramsOnboardingForm from '../components/desktop/Programs/OnboardingForm.vue';
import RecommendedCourses from '../components/desktop/Courses/RecommendedCourses.vue';
import QuestionnairesForm from '../components/desktop/Questionnaires/Form.vue';
import QuestionnairesResponseForm from '../components/desktop/Questionnaires/ResponseForm.vue';
import CareerAspectForm from '../components/desktop/Careers/Aspect/Form.vue';
import CareerForm from '../components/desktop/Careers/Form.vue';
import BasePopup from '../components/desktop/shared/BasePopup.vue';
import ProgramsForm from '../components/desktop/Programs/Form.vue';
import ProgramsAssignmentForm from '../components/desktop/Programs/AssignmentForm.vue';
import ProgramPresetsForm from '../components/desktop/Programs/PresetsForm.vue';
import CoursesPlayer from '../components/desktop/Courses/CoursesPlayer.vue';
import BtnConfirmPopup from '../components/desktop/shared/BtnConfirmPopup.vue';
import VideoPreview from '../components/desktop/Courses/VideoPreview.vue';
import LecturesQuiz from '../components/desktop/Lectures/Quiz.vue';
import EditorQuizForm from '../components/desktop/Editor/Quizzes/Form.vue';
import EditorQuizQuestionForm from '../components/desktop/Editor/Quizzes/Questions/Form.vue';
import QuizChoiceAnswer from '../components/desktop/Editor/Quizzes/Questions/Answers/ChoiceAnswer.vue';
import QuizRankingAnswer from '../components/desktop/Editor/Quizzes/Questions/Answers/RankingAnswer.vue';
import QuizComparisonAnswer from '../components/desktop/Editor/Quizzes/Questions/Answers/ComparisonAnswer.vue';
import QuizFillingAnswer from '../components/desktop/Editor/Quizzes/Questions/Answers/FillingAnswer.vue';
import QuizPictureAnswer from '../components/desktop/Editor/Quizzes/Questions/Answers/PictureAnswer.vue';
import ProgramAssignmentItem from '../components/desktop/Programs/AssignmentItem.vue';
import Longread from '../components/desktop/Lectures/Longread.vue';
import FullsizeImage from '../components/desktop/Lectures/FullsizeImage.vue';
import CourseCompleted from '../components/desktop/Courses/CourseCompleted.vue';
import WelcomePartners from '../components/desktop/Welcome/WelcomePartners.vue';
import WhyEduson from '../components/desktop/Welcome/WhyEduson.vue';
import WelcomeSales from '../components/desktop/Welcome/WelcomeSales.vue';
import HomeworkAttempts from "../components/desktop/Cabinet/HomeworkAttempts.vue";
import HomeworkAttempt from "../components/desktop/Cabinet/HomeworkAttempt.vue";
import Homework from "../components/desktop/Lectures/Homework.vue";
import CabinetMenuToggler from "../components/desktop/shared/CabinetMenuToggler.vue";
import InvestBanner from '../components/desktop/shared/InvestBanner.vue';
import ReferralLink from '../components/desktop/shared/ReferralLink.vue';
import ReferralBanner from '../components/desktop/shared/ReferralBanner.vue';
import Counter from '../components/desktop/shared/Counter.vue';
import CommonBanner from "../components/desktop/shared/CommonBanner.vue";
import HalfPathPopup from "../components/desktop/shared/HalfPathPopup.vue";
import RevitBanner from "../components/desktop/shared/RevitBanner.vue";
import BlogForm from "../components/desktop/Cabinet/BlogForm.vue";
import Post from "../components/desktop/Posts/Post.vue";
import UpsellPopup from '../components/desktop/shared/UpsellPopup.vue';

const emitter = mitt();

const store = createStore({
	state: {
		course_preview_popup: { is_open: false, course: {} },
	},
	mutations: {
		trigger_course_preview_popup(state, popup_state) {
			state.course_preview_popup.is_open = !!popup_state
		},
		set_data_course_preview_popup(state, course_data) {
			state.course_preview_popup.course = course_data
		}
	}
});

document.addEventListener('DOMContentLoaded', () => {
	Array.from(document.getElementsByClassName('vjs')).forEach((el) => {
		const app = createApp();

		app.config.globalProperties.emitter = emitter;
		app.use(store);
		app.use(i18n);

		app.component('draggable', VueDraggableNext);
		app.component('guide', Guide);
		app.component('sandbox', Sandbox);
		app.component('start-assignment-widget', StartAssignmentWidget);
		app.component('start-banner', StartBanner);
		app.component('testing-eng-result', TestingEngResult);
		app.component('time-code', TimeCode);
		app.component('rbc-start', RbcStart);
		app.component('plan-topic', PlanTopic);
		app.component('plan-selection', PlanSelection);
		app.component('wish-selection', WishSelection);
		app.component('user-completed-courses', UserCompletedCourses);
		app.component('catalog-selection', CatalogSelection);
		app.component('course-preview-popup', CoursePreviewPopup);
		app.component('courses-catalog', CoursesCatalog);
		app.component('topic', Topic);
		app.component('courses-presets', CoursesPresets);
		app.component('courses-catalog-header', CoursesCatalogHeader);
		app.component('programs-list', ProgramsList);
		app.component('privacy-popup', PrivacyPopup);
		app.component('term-and-privacy-form', TermAndPrivacyForm);
		app.component('confirm-popup', ConfirmPopup);
		app.component('live-event-form', LiveEventForm);
		app.component('live-event-list', LiveEventList);
		app.component('programs-onboarding-form', ProgramsOnboardingForm);
		app.component('recommended-courses', RecommendedCourses);
		app.component('questionnaires-form', QuestionnairesForm);
		app.component('questionnaires-response-form', QuestionnairesResponseForm);
		app.component('career-aspect-form', CareerAspectForm);
		app.component('career-form', CareerForm);
		app.component('base-popup', BasePopup);
		app.component('programs-form', ProgramsForm);
		app.component('programs-assignment-form', ProgramsAssignmentForm);
		app.component('program-presets-form', ProgramPresetsForm);
		app.component('courses-player', CoursesPlayer);
		app.component('btn-confirm-popup', BtnConfirmPopup);
		app.component('video-preview', VideoPreview);
		app.component('lectures-quiz', LecturesQuiz);
		app.component('editor-quiz-form', EditorQuizForm);
		app.component('editor-quiz-question-form', EditorQuizQuestionForm);
		app.component('quiz-choice-answer', QuizChoiceAnswer);
		app.component('quiz-ranking-answer', QuizRankingAnswer);
		app.component('quiz-comparison-answer', QuizComparisonAnswer);
		app.component('quiz-filling-answer', QuizFillingAnswer);
		app.component('quiz-picture-answer', QuizPictureAnswer);
		app.component('program-assignment-item', ProgramAssignmentItem);
		app.component('longread', Longread);
		app.component('fullsize-image', FullsizeImage);
		app.component('course-completed', CourseCompleted);
		app.component('welcome-partners', WelcomePartners);
		app.component('why-eduson', WhyEduson);
		app.component('welcome-sales', WelcomeSales);
		app.component('homework-attempts', HomeworkAttempts);
		app.component('homework-attempt', HomeworkAttempt);
		app.component('homework', Homework);
		app.component('cabinet-menu-toggler', CabinetMenuToggler);
		app.component('invest-banner', InvestBanner);
		app.component('referral-link', ReferralLink);
		app.component('referral-banner', ReferralBanner);
		app.component('counter', Counter);
		app.component('common-banner', CommonBanner);
		app.component('half-path-popup', HalfPathPopup);
		app.component('revit-banner', RevitBanner);
		app.component('blog-form', BlogForm);
		app.component('post', Post);
		app.component('upsell-popup', UpsellPopup);

		app.mount(el);
	});
});
